import React from "react";
import kebabCase from "lodash/kebabCase";
import Layout from "../components/layout";
import Sidebar from "../components/sidebar";
import Page from "../components/page";
import { useStaticQuery, graphql, Link } from "gatsby";
import SeoHelmet from "../components/seo-helmet";

const CategoriesListTemplate = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query CategoriesListQuery {
        allMarkdownRemark {
          group(field: frontmatter___category) {
            fieldValue
            totalCount
          }
        }
      }
    `
  );

  const categories = allMarkdownRemark.group;
  return (
    <Layout>
      <SeoHelmet title="Categories" />
      <Sidebar />
      <Page title="Categories">
        <ul>
          {categories.map((category) => (
            <li key={category.fieldValue}>
              <Link to={`/category/${kebabCase(category.fieldValue)}/`}>
                {category.fieldValue} ({category.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </Page>
    </Layout>
  );
};

export default CategoriesListTemplate;
